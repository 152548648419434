export default {
	data: () => ({
		isMobile: false,
		isTablet: false,
		isDesktop: false,
		isBetweenMobileAndTablet: false,
	}),

	created() {
		this.getDeviceType();
	},

	mounted() {
		window.addEventListener('resize', this.getDeviceType);
	},

	beforeDestroy() {
		window.removeEventListener('resize', this.getDeviceType);
	},

	methods: {
		getDeviceType() {
			const clientWidth = window.innerWidth;

			this.isMobile = clientWidth < 768;
			this.isTablet = clientWidth < 1440 && clientWidth > 767;
			this.isDesktop = clientWidth >= 1440;
		},
	},
};

// took it from ADV team https://adv.ru/
