<template>
	<main class="vcc-main">
		<div :class="['big-webinar-card', { 'bwc__past': getWebinar.is_ended, 'bwc__onair': getWebinar.is_onair }]" v-if="!animation">
			<aside class="bwc--status">
				<strong id="onair" class="status--heading" hidden>Проходит прямо сейчас</strong>
				<strong id="past" class="status--heading" v-if="getWebinar.is_ended">Прошел:</strong>
				<strong id="planned" class="status--heading"  v-else>Запланирован:</strong>
				<time class="bwc--datetime" :datetime="getWebinar.started">
					<span>{{ normalizeTime(getWebinar.started) }}</span><br>
					<span>{{ normalizeDate(getWebinar.started) }}</span>
				</time>
			</aside>
			<article class="bwc--main">
				<h3>{{ getWebinar.title }}</h3>
				<p>{{ getWebinar.description }}</p>
				<section class="bwc--section">
					<hr>
					<b class="">Докладчики:</b>
					<dl class="wc--speakers dl__theme" aria-label="Список спикеров" v-if="typeof(getWebinar.speakers) !== 'string' && getWebinar.speakers && getWebinar.speakers.length > 0">
						<div class="speakers--description" v-for="speaker, speaker_id in getWebinar.speakers" :key="'speaker-id-'+speaker_id">
							<dt class="hl__blue">{{ speaker.organization }}</dt>
							<dd>{{ speaker.surname + ' ' + speaker.name }}</dd>
						</div>
					</dl>
					<dl class="wc--speakers dl__theme" aria-label="Список спикеров" v-else>
						<div class="speakers--description">
							<dt class="hl__blue">{{ getWebinar.organization }}</dt>
							<dd>{{ getWebinar.speakers }}</dd>
						</div>
					</dl>
				</section>
				<section v-if="getWebinar.files && getWebinar.files.length > 0" class="bwc--section">
					<b>Сопроводительные материалы: </b>
					<file-list :files="getWebinar.files"/>
				</section>
				<div class="bwc--footer bwc--buttons">
					<router-link :to="{ name: 'SubscribeToWebinar', params: { id: getWebinar.id} }" class="link__btn btn__blue" v-if="!getWebinar.is_ended">Записаться</router-link>
					<a :href="getWebinar.materials_link" target="__blank" id="materials" class="link__btn btn__white" v-if="getWebinar.is_ended && getWebinar.materials_link">Материалы</a>
					<a :href="getWebinar.record_link" target="__blank" id="watchpast" class="link__btn btn__blue" v-if="getWebinar.is_ended && getWebinar.record_link">Смотреть в записи</a>
					<a href="#" id="join" target="__blank" class="link__btn btn__blue" hidden>Присоедениться</a>
				</div>
			</article>
		</div>
		<div class="vcc-main main__loading" v-else>
			<transition name="fade-in">
				<book/>
			</transition>
		</div>
		<calendar-section :is-expanded="isDesktop"/>
		<vcc-faq />
	</main>
</template>

<script>
import CalendarSection from '@/components/CalendarSection.vue'
import Faq from '@/components/FaqSection.vue'
import FileList from '@/components/common/FileList.vue'
import book from '@/components/animations/book.vue';
import deviceWidth from '@/mixins/deviceWidth.js';

import { mapActions, mapGetters, mapMutations } from 'vuex'
export default {
	props: ['id'],
	components: {
		'calendar-section': CalendarSection,
		'vcc-faq': Faq,
		FileList,
		book,
	},
	mixins: [ deviceWidth ],
	data: () => ({
		animation: false,
	}),
	computed: mapGetters(['getWebinar', 'getWebinars', 'getWebinarFiles']),
	watch: {
		id(oldV, newV) {
			if(oldV != newV) this.fetchWebinar(this.id)
		}
	},
	methods: {
		...mapActions(['fetchWebinar']),
		...mapMutations(['cleanUpWebinar']),
		toggleAnimation() {
			return this.animation ? this.animation = false : this.animation = true
		}
	},
	created() {
		this.toggleAnimation()
		this.fetchWebinar(this.id)
		setTimeout(this.toggleAnimation, 1000)
	},
}
</script>

<style scoped>
	.main__loading {
			display: flex;
			justify-content: center;
			align-items: center;
			height: 50vh;
			width: 100vw;
	}
</style>
