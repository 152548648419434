<template>
	<section class="calendar">
		<h2 class="subheading heading__hr">Календарь</h2>
		<div class="calendar-wrapper">
			<vc-calendar
				class="custom-calendar"
				:class="{ 'max-w-full': isExpanded  }"
				:masks="masks"
				:attributes="isExpanded ? calendarAttrs : smallCalendarAttrs"
				:disable-page-swipe="disablePageSwipe"
				:is-expanded="isExpanded"
				@dayclick="showTodaysWebianar($event.attributes)"
			>
				<template v-if="isExpanded" #day-content="{ day, attributes }">
					<div class="day" @click="showTodaysWebianar(attributes)">
						<span class="day-label">{{ day.day }}</span>
						<div class="day-text-wrapper">
							<p
								v-for="attr in attributes"
								:key="attr.key"
								:class="['day-text', {'today': currentDay(attr)}]"
							>
							{{ attr.customData.title }}
							</p>
						</div>
					</div>
				</template>
			</vc-calendar>
		</div>
		<webinar-card :webinar_data="todays_event[0]" v-if="todays_event.length > 0"/>
	</section>
</template>

<script>
import Webinar from '@/components/cards/Webinar.vue'
import { mapActions, mapGetters } from 'vuex';

export default {
	data() {
		return {
			masks: {
				weekdays: 'WWW',
			},
			todays_event: [],
		};
	},
	props: {
		isExpanded: {
			type: Boolean,
			default: true,
		},
		disablePageSwipe: {
			type: Boolean,
			default: true,
		},
	},
	components: {
		'webinar-card': Webinar,
	},
	computed: {
		...mapGetters([
			'getAbsolubleyAllWebinarsForCalendar',
			'getAbsolubleyAllWebinars',
		]),
		calendarAttrs() {
			return this.getAbsolubleyAllWebinarsForCalendar
		},
		smallCalendarAttrs() {
			return this.getAbsolubleyAllWebinarsForCalendar.map((webinar) => ({
				...webinar,
				dot: true,
			}))
		},
	},
	methods: {
		...mapActions(['fetchAllWebinars', 'fetchAllWebinarsFromWebinarRu']),
		showTodaysWebianar(attr) {
			if (attr.length > 0) {
				this.todays_event = this.getAbsolubleyAllWebinars.filter((event) => {
					return event.id == attr[0].customData.id ? event : null
				})
			}
		},
		currentDay(attr) {
			if(this.todays_event.length > 0) {
				if(attr.customData.id == this.todays_event[0].id) return true
			} else {
				return false
			}
		},
		dayClicked(evt) {
			console.log(evt)
		}
	},
	created() {
		if(this.getAbsolubleyAllWebinarsForCalendar.length == 0) {
			this.fetchAllWebinars()
			this.fetchAllWebinarsFromWebinarRu()
		}
	},
};
</script>

<style>
	.calendar-wrapper {
		margin-top: 41px;
		margin-bottom: 25px;
	}


	.calendar {
		padding: 0 10px;
		margin: 100px auto 0 auto;
		max-width: 1060px;
	}

	.vc-container .vc-is-expanded {
		max-width: 100%;
	}

	.vc-header {
		background-color: var(--dark-blue);
		color: #ffffff;
		padding: 0 !important;
		height: 66px;
		display: flex;
		align-items: center;
		padding: 0;
	}

	.vc-header .vc-title {
		color: inherit;
		text-transform: capitalize;
		padding: 0;
		font-size: var(--subheading-fs);
		flex-wrap: var(--subheading-fw);
	}

	.vc-arrows-container {
		height: 66px;
		padding: 0;
	}

	.custom-calendar {
		overflow: hidden;
	}

	.custom-calendar .vc-weeks {
		padding: 0;
	}

	@media screen and (max-width: 1440px) {
		.custom-calendar {
			max-width: 100%;
			width:  100%;
		}
	}

	.weekday-position-6,
	.weekday-position-7 {
		background-color: var(--calendar-light-blue);
	}

	.custom-calendar {
		border: 2px solid var(--dark-blue);
		padding: 0;
		font-family: inherit;
	}

	.custom-calendar .day {
		overflow: auto;
		height: 109px;
		max-width: 147px;
		padding: 10px;
		cursor: pointer;
		position: relative;
	}

	.day-label {
		position: sticky;
		display: block;
		bottom: 1px;
		left: 0;
		width: 112px;
		background-color: transparent;
	}

	.weekday-position-6 .day-label,
	.weekday-position-7 .day-label {
		background-color: var(--calendar-light-blue);
		box-shadow: 0 -10px 0px 10px var(--calendar-light-blue);
	}

	.custom-calendar .vc-day,
	.custom-calendar .vc-weekday {
		border-right: 1px solid var(--dark-blue);
		border-top: 2px solid var(--dark-blue);
	}

	@media screen and (max-width: 1440px) {
		.custom-calendar .vc-day {
			padding: 15px 8px;
		}

		.custom-calendar .vc-dots {
			transform: translateY(-10px);
		}
	}

	@media screen and (max-width: 769px) {
		.custom-calendar .vc-day {
			padding: 8px;
		}

		.custom-calendar .vc-dots {
			transform: translateY(-5px);
		}
	}

	@media screen and (max-width: 480px) {
		.custom-calendar .vc-day {
			padding: 5px 3px;
		}

		.custom-calendar .vc-dots {
			transform: translateY(-3px);
		}

	}

	.custom-calendar .vc-weekday:nth-child(7),
	.custom-calendar .vc-weekday:nth-child(6) {
		background-color: var(--calendar-light-blue);
	}

	.custom-calendar .vc-weekday:nth-child(7),
	.custom-calendar .on-right {
		border-right: none;
	}

	/* .custom-calendar .on-bottom {
		border: none;
		display: none;
	} */

	.custom-calendar .day-text {
		font-size: 10px;
		background-color: var(--dark-blue);
		font-weight: bold;
		color: #ffffff;
		padding: 5px;
		border-radius: 9px;
	}

	.custom-calendar .today {
		background-color: var(--light-blue);
	}

	.custom-calendar .vc-weekday {
		height: 42px;
		font-size: var(--subheading-fs);
		color: var(--light-gray);
		padding: 12px 0;
	}

	.vc-arrow svg {
		display: none !important;
	}

	.vc-arrows-container {
		padding: 0 12px;
	}

	.vc-arrow {
		width: 12px;
		height: 22px;
		display: block;
		align-self: center;
	}

	.vc-arrows-container .is-left:hover,
	.vc-arrows-container .is-left {
		background: center / contain no-repeat url("data:image/svg+xml,%3Csvg width='12' height='24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M9.897 23.083a1.583 1.583 0 0 1-1.235-.585l-7.647-9.5a1.583 1.583 0 0 1 0-2.011l7.917-9.5a1.585 1.585 0 0 1 2.438 2.026L4.292 12l6.84 8.487a1.583 1.583 0 0 1-1.235 2.596Z' fill='%23fff'/%3E%3C/svg%3E") !important;
	}

	.vc-arrows-container .is-right:hover,
	.vc-arrows-container .is-right {
		background: center / contain no-repeat url("data:image/svg+xml,%3Csvg width='12' height='24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M2.103.917a1.583 1.583 0 0 1 1.235.585l7.647 9.5a1.583 1.583 0 0 1 0 2.011l-7.917 9.5A1.586 1.586 0 0 1 .63 20.487L7.708 12 .868 3.513A1.583 1.583 0 0 1 2.103.917Z' fill='%23fff'/%3E%3C/svg%3E") !important;
	}

	.custom-calendar .vc-arrow:hover {
		background-color: transparent;
		scale: 1;
	}
</style>
