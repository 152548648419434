<template>
	<ul class="q-file-list">
		<li v-for="file in files" :key="'file-id-' + file.id">
			<a class="a__nostyle" :href="filePath(file)" target="__blank" v-if="filePath(file)">{{ file.name }}</a>
			<span v-else>{{ file.name }}</span>
		</li>
	</ul>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
	name: "FileList",
	props: {
		files: {
			type: Array,
			required: true,
		}
	},
	computed: mapGetters(['getStaticStoragePath']),
	methods: {
		filePath(file) {
			return this.getStaticStoragePath + (file.path ? file.path : file.url ? file.url : false)
		}
	}
}
</script>

<style scoped>
	.q-file-list {
		font-weight: var(--regular-fw);
		font-size: var(--regular-fs);
		margin-top: 10px;
		column-gap: 21px;
		row-gap: 0;
	}
</style>
